<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="devices-page">
    <b-card class="" title="Devices in the system">
      <b-card-text>This page shows all devices across all enterprises. You can set policies and reboot devices directly from this page.</b-card-text>
    </b-card>

    <b-card no-body class="mb-0">
      <div class="row px-2 py-1">

        <!--  Switch - Show deleted  -->
        <b-col cols="12" md="3" class="py-1">
          <b-form-checkbox name="check-button" @change="getMoreDevices(1)" v-model="filters.deleted" switch>
            Show deleted devices
          </b-form-checkbox>
        </b-col>

        <b-col cols="12" md="3"  class="py-1">
          <b-form-select @change="getMoreDevices(1)" v-model="filters.secure">
            <b-form-select-option value="">Secure: Show All</b-form-select-option>
            <b-form-select-option value="true">Secure</b-form-select-option>
            <b-form-select-option value="false">Unsecure</b-form-select-option>
          </b-form-select>

        </b-col>

        <b-col cols="12" md="3"  class="py-1">
          <b-form-select @change="getMoreDevices(1)" v-model="filters.encrypted">
            <b-form-select-option value="">Encrypted: Show All</b-form-select-option>
            <b-form-select-option value="true">Encrypted</b-form-select-option>
            <b-form-select-option value="false">Unencrypted</b-form-select-option>
          </b-form-select>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="3" class="py-1">
          <b-input-group>
            <b-form-input
              v-model="filters.search"
              @keydown.enter="getMoreDevices(1)"
              class="d-inline-block" placeholder="Owner/Ent ID or Serial Number..."
            />
            <b-input-group-append>
              <b-button @click="getMoreDevices(1)">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </div>

      <section>
        <div class="table-responsive">
          <b-table
            class="data-table"
            hover
            :items="devices"
            :fields="headers"
            :current-page="currentPage"
            :per-page="0"
          >
            <template #cell(owner.identifier)="data">
              <router-link :to="{ name: 'owner-view', params: { uid: data.item.owner_uid } }" class="font-weight-bold d-block text-nowrap" :class="{ 'text-secondary': data.item.deleted_at }">
                {{ data.item.owner ? data.item.owner.identifier : "" }}
              </router-link>
            </template>
            <template #cell(updated_at)="data">
              {{data.item.updated_at | formatDateTime}}
            </template>
            <template #cell(is_device_secure)="data">
              <span v-if="data.item.allow_ota_upgrade">
                <span v-b-tooltip.hover="'OTA updates are allowed on this device'" style="color: lightgreen">
                  <i class="fas fa-arrow-circle-up" />
                </span>
                {{ data.item.is_device_secure | boolToYes }}
              </span>
              <span v-else>
                {{ data.item.is_device_secure | boolToYes }}
              </span>

              <span v-if="data.item.policy_override" v-b-tooltip.hover="'WARNING The policy on this device has been isolated. It may not receive further updates'">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
            </template>

            <template #cell(applied_policy_version)="data">
              {{data.item.applied_policy_version}}
            </template>

            <template #cell(policy_compliant)="data">
              {{data.item.policy_compliant | boolToYes}}
            </template>

            <template #cell(api_level)="data">
              {{data.item.android_version ? data.item.android_version : '' }} ({{data.item.api_level ? data.item.api_level : '?' }}) {{data.item.security_patch_level ? data.item.security_patch_level : '' }}
            </template>

            <template #cell(hardware_info_serial_number)="data">
              <router-link :to="{ name: 'admin-device-view', params: { uid: data.item.uid } }" class="font-weight-bold d-block text-nowrap" :class="{ 'text-secondary strike-through': data.item.deleted_at }">
                {{data.item.hardware_info_serial_number}}
              </router-link>
            </template>

            <template #cell(applied_policy_version)="data">
              <PolicyInfo :group-version="data.item.group_policy_version" :applied-version="data.item.applied_group_policy_version"/>
            </template>

            <template #cell(owner.identifier)="data">
              {{data.item.disabled ? 'Disabled' : data.item.owner ? data.item.owner.identifier : "" }}

              <span v-if="data.item.disabled" v-b-tooltip.hover="'WARNING This device has been disabled'">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            </template>
          </b-table>
          <b-row>
            <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
              <b-pagination @change="getMoreDevices" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
            </b-col>
            <b-col class="ml-2 mt-1" v-if="total_count === 0">
              <p >No results</p>
            </b-col>
          </b-row>
        </div>
      </section>
    </b-card>
  </div>
</template>
<!--eslint-enable-->

<script>
import AdminDeviceService from "@/services/AdminDeviceService";
import PolicyInfo from "@/views/devices/viewDevice/sections/PolicyInfo.vue";

export default {
  name: 'AdminDevices',
  components: {PolicyInfo},
  props: {},
  data() {
    return {
      headers: [
        {
          key: 'hardware_info_serial_number',
          label: 'Serial Number',
        },
        {
          key: 'hardware_info_brand',
          label: 'Brand',
        },
        {
          key: 'is_device_secure',
          label: 'Secure',
        },
        {
          key: 'policy_compliant',
          label: 'Compliant',
        },
        {
          key: 'api_level',
          label: 'Android (API) Security',
        },
        {
          key: 'applied_policy_version',
          label: 'Policy',
        },
        {
          key: 'enterprise_uid',
          label: 'Enterprise ID',
        },
        {
          key: 'owner.identifier',
          label: 'Owner',
        },
        {
          key: 'updated_at',
          label: 'Updated At',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      devices: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 25,
        search: '',
        secure: '',
        encrypted: '',
        deleted: false,
      },
      total_count: 0,
    };
  },
  mounted() {
    this.setFilters();
    this.getDevices();
  },
  methods: {
    getMoreDevices(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getDevices();
    },
    setFilters() {
      this.filters.secure = this.$route.query.secure || ''
      this.filters.encrypted = this.$route.query.encrypted || ''
      this.filters.deleted = this.$route.query.deleted || false
    },
    getDevices() {
      AdminDeviceService.getAdminDevices(this.filters).then(res => {
        this.devices = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get devices, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 25,
        search: '',
      };

      this.getMoreDevices(1);
    },
  },
};
</script>
